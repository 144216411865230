<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="이러닝 업무요청"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div v-if="view.isLoaded==true">
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="420">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>제목</th>
                                <td colspan="3">{{ view.info.title }}</td>
                            </tr>
                            <tr>
                                <th>요청자</th>
                                <td>{{ view.info.hq_ename }}({{ view.info.hq_kname }})
                                </td>
                                <th>요청등록일</th>
                                <td>{{ view.info.request_date }}</td>
                            </tr>
                            <tr>
                                <th>분류</th>
                                <td>{{ view.info.ctype }}</td>
                                <th>희망완료일</th>
                                <td>{{ view.info.complete_date }}</td>
                            </tr>
                            <tr>
                                <th>담당자</th>
                                <td>
                                    <select v-model="view.manager" @change="view.setManager" class="w-200px">
                                        <option value="0">전체</option>
                                        <option v-for="(irow, i) in view.mgr_list" :key="i" :value="irow.idx">{{ irow.ename }}({{ irow.kname }})</option>
                                    </select>
                                    <span class="ml-5 txt-red">* 담당자 배정 후 내용 수정불가</span>
                                    <!-- <template v-if="view.info.idx_hq>0">{{ view.info.mgr_ename }}({{ view.info.mgr_kname }})</template>
                                    <template v-else>
                                        <select v-model="view.info.idx_hq" @change="view.setManager" class="w-200px">
                                            <option value="0">전체</option>
                                            <option v-for="(irow, i) in view.mgr_list" :key="i" :value="irow.idx">{{ irow.ename }}({{ irow.kname }})</option>
                                        </select>
                                        <span class="ml-5">* 담당자 배정 후 내용 수정불가</span>
                                    </template> -->
                                </td>
                                <th>중요도</th>
                                <td>{{ view.info.priority_txt }}</td>
                            </tr>
                            <tr>
                                <th>진행상태</th>
                                <td colspan="3">
                                    <select v-model="view.state" @change="view.setState" class="w-200px">
                                        <option value="">진행상태 전체</option>
                                        <option v-for="(irow, i) in view.stype_list" :key="i" :value="irow.code">{{ irow.name }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>요청 PAGE URL</th>
                                <td colspan="3"><a :href="view.info.request_url" target="_blank">{{ view.info.request_url }}</a></td>
                            </tr>
                            <tr>
                                <td colspan="4" v-html="view.info.contents"></td>
                            </tr>
                            <tr v-if="view.info.file1_name">
                                <th>파일첨부 #1</th>
                                <td colspan="5">
                                    <a :href="view.info.file1_url" class="btn-view" target="_blank">{{ view.info.file1_name }}</a>
                                </td>
                            </tr>
                            <tr v-if="view.info.file2_name">
                                <th>파일첨부 #2</th>
                                <td colspan="5">
                                    <a :href="view.info.file2_url" class="btn-view" target="_blank">{{ view.info.file2_name }}</a>
                                </td>
                            </tr>
                            <tr v-if="view.info.file3_name">
                                <th>파일첨부 #3</th>
                                <td colspan="5">
                                    <a :href="view.info.file3_url" class="btn-view" target="_blank">{{ view.info.file3_name }}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="view.showList" class="btn-default float-left mt-30">목록</button>
                    <button v-if="view.isAuth==true&&view.info.idx_hq<=0" @click="view.doDelete" class="btn-default float-left mt-30 ml-10">삭제</button>
                    <button v-if="view.isAuth==true&&view.info.idx_hq<=0" @click="view.showModify" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>

                    <div class="mt-40 comment-list">
                        <div class="title-box">댓글달기</div>
                        <div class="add-box">
                            <textarea placeholder="댓글을 남겨주세요." v-model="cmt.newtext" class="h-100px"></textarea>
                            <button class="btn" @click="cmt.addCmt(0)">등록</button>
                        </div>

                        <div class="list-box">
                            <!-- 샘플 -->
                            <div class="reply" v-for="(irow, i) in cmt.list" :key="i" :class="cmt.bindDetph(irow.depth)">
                                <div class="list">
                                    <div class="photobox">
                                        <img class="my-profile" :src="irow.photoimg" :alt="irow.ename + '\'s profile'">
                                    </div>
                                    <div class="textbox">
                                        <div class="nameDay">
                                            <span class="name" v-if="irow.kname">{{ irow.ename }}({{ irow.kname }})</span>
                                            <span class="day">{{ irow.wdate }}</span>
                                        </div>
                                        <div class="text preline">{{ irow.contents }}</div>
                                        <div class="text" v-if="irow.is_mode=='A'">
                                            <div class="add-box mt-10">
                                                <textarea placeholder="댓글을 남겨주세요." v-model="cmt.modtext" class="h-100px"></textarea>
                                                <button class="btn type1" @click="cmt.addCmt(irow.idx)">등록</button>
                                            </div>
                                        </div>
                                        <div class="text" v-if="irow.is_mode=='M'">
                                            <div class="add-box mt-10">
                                                <textarea placeholder="댓글을 남겨주세요." v-model="cmt.modtext" class="h-100px"></textarea>
                                                <button class="btn type1" @click="cmt.modCmt(irow.idx)">수정</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btnbox" v-if="irow.depth<5">
                                        <!-- <button v-if="irow.is_writer=='N'" class="btn-add" @click="cmt.showAddCmt(i)">댓글달기</button> -->
                                        <button v-if="irow.is_writer=='Y'" class="btn-edit" @click="cmt.showModCmt(i)">수정</button>
                                        <button v-if="irow.is_writer=='Y'" class="btn-del" @click="cmt.delCmt(i)">삭제</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
// import CarrotStaff from '@/components/common/CarrotStaff.vue'
import { useStore } from 'vuex';


export default {
    layout:"BusinessManagement",
    components: {
        // CarrotStaff
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast  = useToast();
        const store  = useStore();

        const view = reactive({
            isAuth     : false,
            isAuthState : false,
            isLoaded   : false,
            stype_list : [],
            mgr_list   : [],

            idx  : 0,
            info : {},

            manager : 0,
            state  : '',

            showList: () => {
                router.push({
                    name:'BusinessManagement-eLearningList'
                });
            },

            doDelete : () => {
                Swal.fire({
                    title : "이러닝 업무요청",
                    text: '삭제하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : view.idx
                        };
                        axios.post("/rest/businessmgr/eLearningDel", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name: 'BusinessManagement-eLearningList'
                                })
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            showModify: () => {
                router.push({
                    name   : 'BusinessManagement-eLearningMod-idx',
                    params : { 
                        idx: view.idx 
                    }
                });
            },

            doSearchStype: () => {
                let params = { };
                axios.get('/rest/businessmgr/eLearningStype', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.stype_list  = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }

                    sessionStorage.removeItem('eLearningList');
                })
            },

            doSearch: () => {
                let params = {
                    idx : view.idx
                };
                axios.get("/rest/businessmgr/eLearningView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.manager = view.info.idx_hq;
                        view.state   = view.info.state;

                        view.isAuth = view.info.is_auth=='Y'?true:false;

                        view.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            setManager: () => {
                let params = {
                    idx     : view.idx,
                    manager : view.manager
                };
                if( view.info.state == 'DONE' ){
                    Swal.fire({
                        title : '이러닝 업무요청',
                        text  : '수정이 불가합니다.'
                    });
                    view.manager = view.info.idx_hq;
                    return;
                }
                axios.get("/rest/businessmgr/eLearningSetManager", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("적용되었습니다.");
                        view.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            setState: () => {
                let params = {
                    idx     : view.idx,
                    state   : view.state
                };
                if( view.info.state == 'DONE' || view.isAuthState == false ){
                    Swal.fire({
                        title : '이러닝 업무요청',
                        text  : '수정이 불가합니다.'
                    });
                    view.state = view.info.state;
                    return;
                }
                axios.get("/rest/businessmgr/eLearningSetState", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("적용되었습니다.");
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                        view.state = view.info.state;
                    }
                });
            },

            doSearchMgr: () => {
                let params = {
                    type : 'elearning'
                };
                axios.get('/rest/businessmgr/eLearningMgr', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.mgr_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
        });

        const cmt = reactive({
            page : 1,
            newtext:"", modtext:"",
            list : [], total : 0,

            showSmile : (mode) => {
                console.log(mode);
            },

            doSearch: () => {
                let params = {
                    idx  : view.idx,
                    page : cmt.page
                };
                axios.get("/rest/businessmgr/eLearningCmtList", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        cmt.list  = res.data.list;
                        cmt.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            bindDetph: (d) => {
                return 'd-' + d;
            },

            addCmt: (idx_parent) => {
                let params = {
                    idx        : view.idx,
                    comment    : idx_parent>0?cmt.modtext:cmt.newtext,
                    idx_parent : idx_parent
                };
                axios.post("/rest/businessmgr/eLearningCmtAdd", params).then((res) => {
                    if( res.data.err == 0 ){
                        cmt.newtext  = "";
                        view.info.cnt_cmt = res.data.cnt_cmt;
                        cmt.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            showAddCmt: (index) => {
                for(let i=0; i<cmt.list.length; i++){
                    cmt.list[i].is_mode = '';
                }
                cmt.modtext  = "";
                cmt.list[index].is_mode = 'A';
            },

            modCmt: (idx) => {
                let params = {
                    idx        : idx,
                    comment    : cmt.modtext
                };
                axios.post("/rest/businessmgr/eLearningCmtMod", params).then((res) => {
                    if( res.data.err == 0 ){
                        cmt.modtext  = "";
                        cmt.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            showModCmt: (i) => {
                for(let i=0; i<cmt.list.length; i++){
                    cmt.list[i].is_mode = '';
                }
                cmt.list[i].is_mode = 'M';
                cmt.modtext  = cmt.list[i].contents;
            },

            delCmt: (i) => {
                let params = {
                    idx  : cmt.list[i].idx
                };
                axios.post("/rest/businessmgr/eLearningCmtDel", params).then((res) => {
                    if( res.data.err == 0 ){
                        view.info.cnt_cmt = res.data.cnt_cmt;
                        cmt.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.idx_staff == 41 || store.state.idx_team == 937 || store.state.idx_office == 6095 ) {
                view.isAuthState = true;
            }

            view.doSearchStype();
            view.doSearchMgr();

            view.idx = route.params.idx;
            if ( !view.idx ) {
                router.go(-1);
            }

            view.doSearch();
            cmt.doSearch();
        });

        return { view, cmt };
    }
}
</script>

<style lang="scss" scoped>
</style>